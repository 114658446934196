<template>
	<v-card>
		<v-card-title>
			<v-row dense>
				<v-col class="text-center">
					Login
				</v-col>
			</v-row>
		</v-card-title>
		<v-card-text>
			<v-row>
				<v-col class="text-center caption">
					<p class="mb-0">
						Ad blockers may prevent you from having a successful login
					</p>
				</v-col>
			</v-row>
			<v-row
				v-if="error"
				justify="center">
				<v-col cols="auto">
					<v-card
						class="error"
						flat
						color="red">
						<v-card-text>
							<p
								class="mb-0 white--text"
								v-html="error">
							</p>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>
			<v-row>
				<v-col>
					<v-text-field
						v-model="username"
						outlined
						hide-details="auto"
						label="Username"
						@keyup.enter="submitLogin()">
					</v-text-field>
				</v-col>
			</v-row>
			<v-row>
				<v-col>
					<v-text-field
						v-model="password"
						type="password"
						outlined
						hide-details="auto"
						label="Password"
						@keyup.enter="submitLogin()"> 
					</v-text-field>
				</v-col>
			</v-row>
			<v-row justify="center">
                <v-col cols="auto">
                    <v-btn
                        color="warning"
                        to="/sign-up">
                        Sign Up
                    </v-btn>
                </v-col>
				<v-col cols="auto">
                    <v-btn
                        :loading="loading"
                        color="primary"
                        @click="submitLogin()">
                        Login
                    </v-btn>
				</v-col>
			</v-row>
            <v-row
                dense
                class="mt-3">
                <v-col class="text-center">
                    <a
                        class="blue--text caption mb-0"
                        href="https://monerominer.rocks/reset-password/"
                        target="_blank">
                        Forgot Password?
                    </a>
                </v-col>
            </v-row>
		</v-card-text>
	</v-card>
</template>

<script>
    /**
     * @group Forms
     * Login form for validating users against the WordPress database.  Uses WP auto endpoint to return a JWT token.
     */
    export default {
        data() {
            return {
                // @vuese
                // Username for v-model.
                username: '',
                // @vuese
                // Password for v-model
                password: '',
                // @vuese
                // Loading state for when login form is submitted.
                loading: false,
                // @vuese
                // For capturing errors.
                error: ''
            }
        },
        methods: {
            /**
             * @vuese
             * When login form is submitted.
             */
            submitLogin() {
                this.error = ''
                this.loading = true;
                let _this = this;
                this.$http.post(this.$getConst('apiHost') + '/jwt-auth/v1/token', {
                    username: this.username,
                    password: this.password
                }).then(function (response) {
                    let user = {
                        id: response.data.user_id,
                        token: response.data.token,
                        email: response.data.user_email,
                        name: response.data.user_display_name,
                        subscription: response.data.membership ? _this.$_.toInteger(response.data.membership.id) : 0
                    }
                    _this.user = user
                    _this.$cookies.set('user', user)
                    _this.bindUserData()
                    _this.$router.go(-1)
                    _this.loading = false
                }).catch(error => {
                    if (error.response.data.data.status === 403) {
                        this.error = error.response.data.message
                    } else {
                        this.error = 'Sorry something went wrong.'
                    }
                    this.loading = false
                })
            }
        }
    }
</script>

<style lang="scss" scopped>
.error {
	a {
		color: #FFFFFF;
	}
}
</style>