<template>
    <v-container
        :fill-height="!this.$vuetify.breakpoint.xs && !this.$vuetify.breakpoint.sm"
        fluid>
        <v-row
            justify="center">
            <v-col
                sm="8"
                md="6"
                xl="4">
                <login-form>
                </login-form>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import LoginForm from './../components/forms/Login'

    export default {
        metaInfo: {
            titleTemplate: 'Login - %s'
        },
        components: {
            LoginForm
        }
    }
</script>